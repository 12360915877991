import { useEffect, useState, useTransition } from 'react';

export function useWhiteList() {
  const [allowedOrigins, setAllowedOrigins] = useState([]);
  const [isFetchAllowedOriginsPending, startTransition] = useTransition();

  useEffect(() => {
    startTransition(() => {
      setAllowedOrigins(["*"]);
    });
  }, []);

  return {
    allowedOrigins,
    isFetchAllowedOriginsPending,
  };
}
